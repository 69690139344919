@charset "UTF-8";
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
追加分：スクロールを促すアニメーション（メインビジュアル）
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.css_scrollBottom {
  -webkit-animation: sdb 1.5s infinite;
  animation: sdb 1.5s infinite; }

@-webkit-keyframes sdb {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: translate(0px, 10px);
    opacity: 0; } }

@keyframes sdb {
  0% {
    transform: translate(0, 0);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    transform: translate(0px, 10px);
    opacity: 0; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
共通
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media only screen and (min-width: 769px) {
  a {
    transition: all 0.3s ease; }
    a img {
      transition: all 0.3s ease; }
    a:hover {
      transition: all 0.3s ease; }
    a img {
      transition: all .3s ease; }
    a.no_link {
      pointer-events: none; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
グローバルナビゲーション
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media only screen and (min-width: 767px) {
  header .wrap_header .inner_nav ul li a {
    transition: all 0.3s ease; }
    header .wrap_header .inner_nav ul li a:hover {
      background-color: rgba(255, 255, 255, 0.9);
      transform: scale(1.02);
      color: #111;
      transition: all 0.3s ease; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
overflowなしの画像拡大（ロゴとか）
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media only screen and (min-width: 769px) {
  header .wrap_header .inner_box .inner_logo .block_logo:hover img,
  footer .wrap_footer .inner_box .inner_logo .block_logo .item_logo:hover img {
    transform: scale(1.08);
    transition: all 0.3s ease; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
ブロック影付きホバー
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media only screen and (min-width: 769px) {
  header .wrap_header .inner_box .inner_links .block_contact:hover {
    opacity: 1;
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15), 0 10px 30px 0 rgba(0, 0, 0, 0.2); } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
ファーストビューボタン
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media only screen and (min-width: 769px) {
  .wrap_slider .inner_link a {
    overflow: hidden;
    position: relative;
    transition: all .3s;
    z-index: 2; }
    .wrap_slider .inner_link a:after {
      position: absolute;
      z-index: -1;
      display: block;
      content: '';
      transition: all .3s;
      top: -100%;
      left: 0;
      width: 100%;
      height: 100%; }
    .wrap_slider .inner_link a:hover {
      color: #222; }
      .wrap_slider .inner_link a:hover:after {
        top: 0;
        background-color: #fff; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
トップ背景画像拡大
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media only screen and (min-width: 769px) {
  .section_wrap01 .inner_wrap .block_box .block_col .img {
    transition: all 0.3s ease; }
  .section_wrap01 .inner_wrap .block_box .block_col:hover .img {
    transform: scale(1.04);
    transition: all 0.3s ease; }
  .section_wrap01 .inner_wrap .block_box * {
    transition: all 0.3s ease; }
  .section_wrap01 .inner_wrap .block_box:hover .block_col .item_bg {
    filter: grayscale(80%); }
  .section_wrap01 .inner_wrap .block_box:hover .block_col:hover .item_bg {
    filter: grayscale(0%); } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
トップ背景画像拡大
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#js_topBtn {
  transition: all 0.3s ease; }
